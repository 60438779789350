import axios from "axios";
import api_url from './config';

import ls from "local-storage";

const api = axios.create({
    baseURL: api_url,
    headers: {Authorization: `Bearer ` + ls.get('session_token')}
})

export const pointsPerActivity = {
    'cycling': 2,
    'running': 8,
    'workout': 20,
    'swimming': 40,
    'walking': 4,
};

const sortByPph = (a, b) => {
    if (a['pph'] === b['pph']) {
        return 0;
    } else {
        return (a['pph'] > b['pph']) ? -1 : 1;
    }
}
const sortByPoints = (a, b) => {
    if (a['Points'] === b['Points']) {
        return 0;
    } else {
        return (a['Points'] > b['Points']) ? -1 : 1;
    }
}
const sortByName = (a, b) => {
    if (a['Name'] === b['Name']) {
        return 0;
    } else {
        return (a['Name'] < b['Name']) ? -1 : 1;
    }
}

const stripName = (teamName) => {
    teamName = teamName.replace('&', '');
    teamName = teamName.replace('+', '');
    teamName = teamName.replace(' ', '');
    teamName = teamName.replace(' ', '').toLowerCase();
    return teamName;
}

const getFriendlyType = (oldType) => {
    let type ='';

    if (oldType === 'Running (points per Kilometre') {
        type = 'running'
    } else if (oldType === 'Workout (points per Kilometre') {
        type = 'workout'
    } else if (oldType === 'Walking (points per Kilometre') {
        type = 'walking'
    } else if (oldType === 'Cycling (points per Kilometre') {
        type = 'cycling'
    } else if (oldType === 'Swimming (points per Kilometre') {
        type = 'swimming'
    } else if (oldType === 'swimming') {
        type = 'swimming'
    }

    return type;
}

const getTeamActivityData = async () => {

    let allActivity = [];

    // Getting All Activity
    await api.get('/activity/all').then(res => {

        // Setting up variables
        const data = res.data;

        let leaderboard = [];
        let individualLeaderboard = [];
        let orderCounter = 0;
        let total = 0;

        allActivity['Teams'] = [];
        allActivity['Individuals'] = [];
        allActivity['Leaderboard'] = {};


        // Looping through teams
        data.teams.map((team) => {

            let teamPoints = 0;

            allActivity['Teams'][team.team.name] = {
                'Name'  : team.team.name,
                'Slug'  : stripName(team.team.name)
            };

            // Setting up member data for individual leaderboard
            team.members.forEach((member) => {
                individualLeaderboard[member._id] = {
                    'Team': team.team.name,
                    'TeamSlug': stripName(team.team.name),
                    'Id': member._id,
                    'Name': member.firstName + ' ' + member.surname,
                    'Activities': {
                        'cycling': 0,
                        'swimming': 0,
                        'walking': 0,
                        'workout': 0,
                        'running': 0
                    },
                    'Points': 0,
                }
                return '';
            });

            // Looping through activities
            team.activities.forEach((act) => {

                let type = getFriendlyType(act.type);

                // If distance activity add distance to running total
                if(type === 'running' || type === 'walking' || type === 'cycling' || type === 'swimming'){
                    total = total + act.amount;
                }

                // If type is not undefined
                if (type !== '') {

                    // Add points accumulation to the team points
                    teamPoints = teamPoints + (pointsPerActivity[type] * act.amount);

                    // Add points to the individual accumulation
                    individualLeaderboard[act.memberId]['Points'] = individualLeaderboard[act.memberId]['Points'] + (pointsPerActivity[type] * act.amount)

                    // Add activity amount to individual accumulation
    individualLeaderboard[act.memberId]['Activities'][type] = individualLeaderboard[act.memberId]['Activities'][type] + act.amount;


                    if (allActivity['Teams'][team.team.name][type] === undefined) {
                        allActivity['Teams'][team.team.name][type] = act.amount;
                    } else {
                        allActivity['Teams'][team.team.name][type] = allActivity['Teams'][team.team.name][type] + act.amount;
                    }
                }

                allActivity['Teams'][team.team.name]['TotalPoints'] = teamPoints;
                return allActivity;
            });


            let pointsPerHead = ((teamPoints / team.team.size).toFixed(2));
            let PositionPlacement = (1000000000000000 - pointsPerHead);

            if (allActivity['Teams'][team.team.name]['cycling'] === undefined) {
                allActivity['Teams'][team.team.name]['cycling'] = 0;
            }
            if (allActivity['Teams'][team.team.name]['swimming'] === undefined) {
                allActivity['Teams'][team.team.name]['swimming'] = 0;
            }
            if (allActivity['Teams'][team.team.name]['walking'] === undefined) {
                allActivity['Teams'][team.team.name]['walking'] = 0;
            }
            if (allActivity['Teams'][team.team.name]['workout'] === undefined) {
                allActivity['Teams'][team.team.name]['workout'] = 0;
            }
            if (allActivity['Teams'][team.team.name]['running'] === undefined) {
                allActivity['Teams'][team.team.name]['running'] = 0;
            }

            let data = {
                name: team.team.name,
                pph: parseFloat(pointsPerHead),
                cycling: allActivity['Teams'][team.team.name]['cycling'],
                swimming: allActivity['Teams'][team.team.name]['swimming'],
                walking: allActivity['Teams'][team.team.name]['walking'],
                workout: allActivity['Teams'][team.team.name]['workout'],
                running: allActivity['Teams'][team.team.name]['running']
            }

            if (pointsPerHead === 0.00) {
                PositionPlacement += orderCounter;
                leaderboard[PositionPlacement] = data;
                orderCounter += 1;
            } else {
                leaderboard[PositionPlacement] = data;
            }

            allActivity['Teams'][team.team.name]['PointsPerHead'] = parseFloat(pointsPerHead);
            allActivity['TotalDistance'] = parseFloat(total);

            return allActivity;
        });

        //Conversion of Objects to arrays
        leaderboard = Object.values(leaderboard)
        individualLeaderboard = Object.values(individualLeaderboard)

        leaderboard = leaderboard.sort(sortByPph);
        individualLeaderboard.sort(sortByPoints)

        allActivity['Leaderboard'] = leaderboard
        allActivity['Individuals'] = individualLeaderboard
        allActivity['TotalDistance'] = allActivity['TotalDistance'].toFixed(2)

        let teamsArray = Object.values(allActivity['Teams']);
        teamsArray.sort(sortByName)
        allActivity['Teams'] = teamsArray;

    });

    const ActivityData = allActivity;
    console.log(ActivityData);
    return ActivityData;
};

export const ActivityData = getTeamActivityData();
