import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import axios from 'axios';
import Container from '@material-ui/core/Container';
import AddActivity from '../../addactivity/AddActiviy';
import ls from 'local-storage';
import api_url from '../../../config';

import "./Admin.scss";
import logo from '../../../images/havaslynxgames-thetorchrelay.svg';

const baseURL = api_url;

const Admin = props => {

    const [user_email, setUserEmail] = useState('');
    const [user_password, setUserPassword] = useState('');
    const [loggedInStatus, setLoggedInStatus] = useState(false);
    const [formExpired, setFormExpired] = useState(false);

    useEffect(() => {
        const sessiongToken = ls.get('session_token');
        if (sessiongToken != null) {
            setLoggedInStatus(true);
            const api = axios.create({
                baseURL: baseURL,
                headers: {Authorization: `Bearer ` + ls.get('session_token')}
            })
            console.log(api);
        }


        const currentTime = new Date().getTime();
        const targetTime = Date.parse('01 Oct 2020 17:30:00 GMT+01:00');
        if (currentTime > targetTime) {
            setFormExpired(true);
        }

    }, []);

    const handleLogInChange = event => {
        if (event.target.name === 'user_email') {
            setUserEmail(event.target.value);
        } else {
            setUserPassword(event.target.value)
        }
    }

    const handleLogOut = () => {
        setLoggedInStatus(false);
        ls.remove('session_token')
    }

    const handleLogIn = event => {
        event.preventDefault();
        axios({
            method: 'post',
            url: baseURL + 'authentication/validate',
            headers: {
                'Content-Type': 'application/json'
            },
            data: {
                user: user_email,
                password: user_password,
            }
        }).then(response => {
            ls.set('session_token', response.data.token);
            setLoggedInStatus(true);
            window.location.reload(false);

        }).catch(error => {
            console.log(baseURL + 'authentication/validate');
            console.log('errorerror');
            console.log(error)
        });

        axios.post()
    }

    const body = (
        <Container maxWidth="sm">

            <div className="header_admin">
                <Link to="/"><img src={logo} className="header_brand_logo"
                                  alt="Havas Lynx Games - The Torch Relay"/></Link>
                <h3 onClick={handleLogOut}>Log Out</h3>
            </div>

            <AddActivity/>

            <br/>
            {/* <Button label="Home" classBtn="btBkHome" btnLink="/" /> */}

        </Container>
    );
    if (formExpired) {
        return (
            <>
                <div className="wrap-form">
                    <h1>The HLX Games are over!</h1>
                </div>
            </>
        )
    } else {
        if (loggedInStatus) {
            return (
                <>
                    <div className="wrap-form">
                        {body}
                    </div>
                </>
            )
        } else {
            return (
                <>
                    <div className="wrap-form">
                        <form className="login-form" onSubmit={handleLogIn}>
                            <h1>Log In Form</h1>
                            <div className="form-input-material">
                                <label>Log In Email: </label>
                                <input type="text"
                                       name="user_email"
                                       value={user_email}
                                       onChange={handleLogInChange}
                                       placeholder=" Log In Email" className="form-control-material" required/>
                            </div>
                            <div className="form-input-material">
                                <label>Password: </label>
                                <input type="password"
                                       name="user_password"
                                       value={user_password}
                                       onChange={handleLogInChange}
                                       placeholder=" " className="form-control-material" required/>
                            </div>
                            <button type="submit" className="btn btn-primary btn-ghost">Login</button>
                        </form>
                    </div>
                </>
            )
        }
    }
}


export default Admin;
