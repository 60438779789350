import React, {useEffect, useState} from 'react';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import {ActivityData} from '../../global';

import "./Range.scss";
import wavingHand from '../../images/icon_waving-hand-sign.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '840px',
    minWidth: '550px',
    margin: '100px 0',
    display: 'flex',
    alignItems: 'end'
  },
  margin: {
    height: theme.spacing(3),
  },
}));
const PrettoSlider = withStyles({
    root: {
        height: 40,
        color: '#E5E5E5',
        fontFamily: 'GothamBook',
        marginLeft: 60
    },
    thumb: {
        backgroundColor: 'transparent',
        width: 100,
        height: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'first baseline',
        '&:focus, &:hover, &$active': {
            boxShadow: 'none'
        },
    },
    markLabel: {
        fontFamily: 'GothamBook',
        color: '#333333',
        marginTop: 50,
        fontSize: 16,
        '&:nth-last-child(2)': {
            fontFamily: 'GothamBold'
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-2% + 4px)',
        fontFamily: 'GothamBook',
    },
    track: {
        background: 'linear-gradient(90deg, rgba(238,118,1,1) 0%, rgba(231,33,110,1) 50%)',
        height: 36,
        borderRadius: '18px 0 0 18px',
        marginTop: 5,
        marginLeft: 5,
    },
    rail: {
        height: 36,
        borderRadius: 20,
        background: '#F2F2F2',
        border: '2px solid #E5E5E5',
        padding: 3,
    },
})(Slider);

const PrettoSliderGoal = withStyles({
    root: {
        height: 40,
        color: '#E5E5E5',
        fontFamily: 'GothamBook',
        marginLeft: 60,
        marginTop: -65
    },
    thumb: {
        backgroundColor: 'transparent',
        width: 100,
        height: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'first baseline',
        '&:focus, &:hover, &$active': {
            boxShadow: 'none'
        },
    },
    markLabel: {
        fontFamily: 'GothamBook',
        color: '#333333',
        marginTop: 50,
        fontSize: 16,
        '&:nth-last-child(2)': {
            fontFamily: 'GothamBold'
        },
    },
    active: {},
    valueLabel: {
        left: 'calc(-2% + 4px)',
        fontFamily: 'GothamBook',
    },
    track: {
        background: 'linear-gradient(90deg, rgba(238,118,1,1) 0%, rgba(231,33,110,1) 50%)',
        height: 36,
        borderRadius: '18px',
        marginTop: 5,
        marginLeft: 5,
    },
    rail: {
        height: 36,
        borderRadius: 20,
        background: '#F2F2F2',
        border: '2px solid #E5E5E5',
        padding: 3,
    },
})(Slider);
const distanceManToTokyo = 9450;

const marks = [
    {
        value: 0,
        label: 'Manchester 0Km',
    },
    {
        value: 26.90,
        label: 'Moscow 2542Km',
    },
    {
        value: 39.63,
        label: 'Cairo 3745Km',
    },
    {
        value: 72.08,
        label: 'Delhi 6812Km',
    },
    {
        value: 85.57,
        label: 'Beijing 8086Km',
    },
    {
        value: 100,
        label: 'Tokyo '+distanceManToTokyo+'km',
    },
];


function valuetext(value) {
    return `${value}°C`;
}

function valueLabel(value) {
    return `${value}`;
}

const workOutPercentage = (value) => {

    let total = distanceManToTokyo;
    let percent = (value / total) * 100;
    percent = percent.toFixed(2);
    return percent;
}

export default function DiscreteSlider() {
    const [distance, setDistance] = useState(0);
    const [distancePercentage, setDistancePercentage] = useState(0);

    useEffect(() => {

        ActivityData.then((res) => {
            setDistance(res.TotalDistance);
            setDistancePercentage(workOutPercentage(res.TotalDistance));
        })

    });

    function SliderThumbComponent(props) {
        return (
            <span {...props}>
      <div className="thumb-component">
        <img src={wavingHand} className="footer_login" alt=""/>
        <p className="colorDarkGrey center"><span className="fontBold">We are here </span><br/>{distance}Km</p>
      </div>
    </span>
        );
    }

    const classes = useStyles();

   
   if (distancePercentage >= '9450') {

        console.log(distancePercentage);
        return (
            <div className={`range ${classes.root}`}>
                <p className="range_label">Start</p>
                <PrettoSliderGoal
                    defaultValue={parseInt(valueLabel(distancePercentage))}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="on"
                    marks={marks}
                    ThumbComponent={SliderThumbComponent}
                    disabled
                />
                <p className="range_label range_label_goal">Goal!</p>
            </div>
        );
    }
    if (distancePercentage > 0) {
        return (
            <div className={`range ${classes.root}`}>
                <p className="range_label">Start</p>
                <PrettoSlider
                    defaultValue={parseInt(valueLabel(distancePercentage))}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider-custom"
                    valueLabelDisplay="on"
                    marks={marks}
                    ThumbComponent={SliderThumbComponent}
                    disabled
                />

                <p className="range_label range_label_goal">Goal!</p>
            </div>
        );
    } 
    else {
        return (
            <></>
        )
    }
}

