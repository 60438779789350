import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Container from '@material-ui/core/Container';

import "./Footer.scss";

import logo from '../../images/havaslynxgames-thetorchrelay.svg';
import twitter from '../../images/icon_twitter.png';
import insta from '../../images/icon_instagram.png';
import facebook from '../../images/icon_facebook.png';
import login from '../../images/icon_login.png';
import logoAmbassador from '../../images/logo_ambassador.png';
import logoHighives from '../../images/logo_high5ives.png';

export default class Footer extends Component {

  render() {
    return (
      <footer className="footer">
        <Container maxWidth="lg">
          <div className="footer_inner">
            <div className="footer_brand">
              <img alt="" className="footer_brand_logo" loading="lazy" src={logo} />
              <Link to="/admin"><span className="footer_social_login_text">Ambassador Login</span> <img alt="" className="footer_social_login_icon" loading="lazy" src={login} /></Link>
            </div>
            <div className="footer_social">
              <div className="footer_social_icons">
                <a href="https://www.instagram.com/havaslynxeu/" target="_blank" rel="noopener noreferrer"><img alt="Instagram" src={insta} loading="lazy" /></a>
                <a href="https://www.facebook.com/HavasLynxGroup/" target="_blank" rel="noopener noreferrer"><img alt="Facebook" src={facebook} loading="lazy" /></a>
                <a href="https://twitter.com/HAVASLYNXEU" target="_blank" rel="noopener noreferrer"><img alt="Twitter" src={twitter} loading="lazy" /></a>
              </div>
              <div className="footer_social_login">
              </div>
            </div>
            <div className="footer_logos">
              <img alt="Ambassador Logo" className="footer_logos_ambassador" loading="lazy" src={logoAmbassador} />
              <img alt="high5ives" className="footer_logos_high5ives" loading="lazy" src={logoHighives} />
            </div>
          </div>
        </Container>
      </footer>
    )
  }
}
