import React, {useEffect, useState} from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import './LeaderboardTable.scss';
import iconCycling from '../../../images/table_icon-cycling.png';
import iconRunning from '../../../images/table_icon-running.png';
import iconWalking from '../../../images/table_icon-walking.png';
import iconWorkout from '../../../images/table_icon-workout.png';
import iconSwimming from '../../../images/table_icon-swimming.png';

import iconTrophyGold from '../../../images/icon_trophy-gold.png';
import iconTrophyBronze from '../../../images/icon_trophy-bronze.png';
import iconTrophySilver from '../../../images/icon_trophy-silver.png';
// import Admin from "../../pages/admin/Admin";
import {ActivityData, pointsPerActivity} from "../../../global";

function createData(icon, name, cycling, running, walking, workout, swimming, total) {
    return {icon, name, cycling, running, walking, workout, swimming, total};
}

const LeaderboardTable = props => {

    const maxIndividualRows = 15;
    const [rows, updateRows] = useState([]);
    const [tableType, setTableType] = useState('');

    useEffect(() => {

        // console.log(props);

        if (props.data === 'global') {
            setTableType('team');
            ActivityData.then((res) => {

                const data = res.Leaderboard;
                let newRows = [];
                let counter = 1;
                data.forEach((team) => {
                    let icon = null;
                    if (counter === 1) {
                        icon = iconTrophyGold
                    } else if (counter === 2) {
                        icon = iconTrophySilver
                    } else if (counter === 3) {
                        icon = iconTrophyBronze
                    }

                    if (team.cycling !== 0 || team.running !== 0 || team.walking !== 0 || team.workout !== 0 || team.swimming !== 0) {
                        newRows.push(createData(icon, counter + '. ' + team.name, team.cycling.toFixed(2), team.running.toFixed(2), team.walking.toFixed(2), team.workout.toFixed(2), team.swimming.toFixed(2), team.pph.toFixed(2) + 'pts'));
                        counter++;
                    }

                })

                updateRows(newRows);
            });
        }if(props.data === 'individual'){
            setTableType('individual');

            ActivityData.then((res) => {

                const data = res.Individuals;
                let newRows = [];
                let counter = 1;
                data.forEach((person) => {
                    let icon = null;
                    if (counter === 1) {
                        icon = iconTrophyGold
                    } else if (counter === 2) {
                        icon = iconTrophySilver
                    } else if (counter === 3) {
                        icon = iconTrophyBronze
                    }
                    if (person.Activities.cycling !== 0 || person.Activities.running !== 0 || person.Activities.walking !== 0 || person.Activities.workout !== 0 || person.Activities.swimming !== 0) {
                        if(counter <= maxIndividualRows) {
                            newRows.push(createData(icon, counter + '. ' + person.Name, person.Activities.cycling.toFixed(2), person.Activities.running.toFixed(2), person.Activities.walking.toFixed(2), person.Activities.workout.toFixed(2), person.Activities.swimming.toFixed(2), person.Points.toFixed(2) + 'pts'));
                        }
                        counter++;
                    }

                })

                updateRows(newRows);
            });

        } else if(props.data === 'individualTeams'){

          ActivityData.then((res) => {

                const data = res.Individuals;
                let newRows = [];
                let counter = 1;
                data.forEach((person) => {
                    let icon = null;
                    if (counter === 1) {
                        icon = iconTrophyGold
                    } else if (counter === 2) {
                        icon = iconTrophySilver
                    } else if (counter === 3) {
                        icon = iconTrophyBronze
                    }

                    const stripName = (teamName) => {
                      teamName = teamName.replace('&', '');
                      teamName = teamName.replace('+', '');
                      teamName = teamName.replace(' ', '');
                      teamName = teamName.replace(' ', '').toLowerCase();
                      return teamName;
                    }

                    const teamUrlPath = `${window.location.pathname}`;
                    const teamUrl = teamUrlPath.replace('/team/', '')

                    if (stripName(person.Team) === teamUrl) {
                        if (person.Activities.cycling !== 0 || person.Activities.running !== 0 || person.Activities.walking !== 0 || person.Activities.workout !== 0 || person.Activities.swimming !== 0) {
                            if(counter) {
                                newRows.push(createData(icon, counter + '. ' + person.Name, person.Activities.cycling.toFixed(2), person.Activities.running.toFixed(2), person.Activities.walking.toFixed(2), person.Activities.workout.toFixed(2), person.Activities.swimming.toFixed(2), person.Points.toFixed(2) + 'pts'));
                              }
                              counter++;
                        }

                      
                    }
                })

                updateRows(newRows);

          });

      }
    },[props]);

    const tableDescr = (desc) => {
        if(desc === 'team'){
            return (
                <span className="leaderboardtable_th_label">Points Per Head</span>
            )
        }else{
            return <></>
        }
    }

    return (
        <TableContainer className="leaderboardtable">
            <Table aria-label="Team leaderboard table">
                <TableHead>
                    <TableRow>
                        <TableCell><span className="leaderboardtable_th_icon"></span><span
                            className="leaderboardtable_th_title"></span></TableCell>
                        <TableCell><span className="leaderboardtable_th_icon"><img alt="" height="40" src={iconCycling}
                                                                                   width="40"/></span><span
                            className="leaderboardtable_th_title">Cycling</span><span
                            className="leaderboardtable_th_label">({pointsPerActivity.cycling}pts per KM)</span></TableCell>
                        <TableCell><span className="leaderboardtable_th_icon"><img alt="" height="40" src={iconRunning}
                                                                                   width="40"/></span><span
                            className="leaderboardtable_th_title">Running</span><span
                            className="leaderboardtable_th_label">({pointsPerActivity.running}pts per KM)</span></TableCell>
                        <TableCell><span className="leaderboardtable_th_icon"><img alt="" height="40" src={iconWalking}
                                                                                   width="40"/></span><span
                            className="leaderboardtable_th_title">Walking</span><span
                            className="leaderboardtable_th_label">({pointsPerActivity.walking}pts per KM)</span></TableCell>
                        <TableCell><span className="leaderboardtable_th_icon"><img alt="" height="40" src={iconWorkout}
                                                                                   width="40"/></span><span
                            className="leaderboardtable_th_title">Workout</span><span
                            className="leaderboardtable_th_label">({pointsPerActivity.workout}pts per HR)</span></TableCell>
                        <TableCell><span className="leaderboardtable_th_icon"><img alt="" height="40" src={iconSwimming}
                                                                                   width="40"/></span><span
                            className="leaderboardtable_th_title">Swimming</span><span
                            className="leaderboardtable_th_label">({pointsPerActivity.swimming}pts per KM)</span></TableCell>
                        <TableCell align="right"><span className="leaderboardtable_th_icon"></span><span
                            className="leaderboardtable_th_title">Total</span>
                            {tableDescr(tableType)}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row">{row.icon && (
                                <img alt="" height="20" src={row.icon} width="20"/>)} {row.name}</TableCell>
                            <TableCell>{row.cycling}</TableCell>
                            <TableCell>{row.running}</TableCell>
                            <TableCell>{row.walking}</TableCell>
                            <TableCell>{row.workout}</TableCell>
                            <TableCell>{row.swimming}</TableCell>
                            <TableCell align="right">{row.total}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default LeaderboardTable;
