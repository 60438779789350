import React from 'react';
import Container from '@material-ui/core/Container';
import Button from '../../button/Button';

import "./Welcome.scss";
import imageLarge from '../../../images/jonny-and-lenny.jpg';
import imageSmall from '../../../images/Matt-and-Ben.jpg';

export default function Welcome() {
  return (
    <div className="section welcome">
      <Container className="welcome_inner" maxWidth="lg">
        <div className="welcomeFlex">
          <div className="welcomeBody">
            <h1>Welcome to the Havas Lynx Games</h1>
            <p>The Tokyo 2020 Olympics may be cancelled, but that won’t stop The Lynx Games!</p>
            <p>The Lynx Games is a new, fierce and fun competition that gives us the opportunity to reconnect with each other and raise some funds for The Christie.</p>
            <Button label="Donate" classBtn="btDonate" btnLink="https://www.justgiving.com/fundraising/hlxgames" />
          </div>
          <div className="welcomeMedia">
            <div className="welcomeMediaItem welcomeMediaItem1">
              <figure className="aspectRatio aspectRatio-1x1"><img alt="" className="aspectRatioMedia" loading="lazy" src={ imageLarge } /></figure>
            </div>
            <div className="welcomeMediaItem welcomeMediaItem2">
              <figure className="aspectRatio aspectRatio-1x1"><img alt="" className="aspectRatioMedia" loading="lazy" src={ imageSmall } /></figure>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
