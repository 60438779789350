import React, {useState, useEffect} from 'react';
import axios from 'axios';
import ls from "local-storage";
import Button from '@material-ui/core/Button';

import AddMember from '../addmember/AddMember'

import "./AddActivity.scss";

const api = axios.create({
    baseURL: `https://lynx-torch-api.hlxgames.com/`,
    headers: {Authorization: `Bearer ` + ls.get('session_token')}
})


const AddActivity = props => {

    const [username, setUsername] = useState('disabled');
    // const [teams, setTeams] =  useState([]);
    const [duration, setDuration] = useState('');
    const [type, setType] = useState('disabled');
    const [useractivitiestype, setUserActivitiesType] = useState([]);
    const [usermembersname, setUserMembersName] = useState([]);

    useEffect(() => {
        api.get('/api/member/team').then(res => {
            setUserMembersName(res.data)
            console.log(res.data)

        })

        api.get('/api/activityTypes').then(res => {
            setUserActivitiesType(res.data)
        })


    }, []);

    const handleSubmit = event => {
        event.preventDefault();

        const div = document.createElement('div');
        div.innerHTML = `Submitted`;


        const refresh = document.createElement('button');
        refresh.className = "btn btn_new-entry";
        refresh.innerHTML = `New entry`;


        document.getElementById('login-form').innerHTML = 'Submitted';
        document.getElementById('new-entry').appendChild(refresh);


        console.log(username + " " + duration + " " + type);
        api.put(`/api/activity`, {id: username, amount: duration, type: type})
            .then(res => {
                console.log(res);
                console.log(res.data);
            })
            .catch(err => {
                console.log('error', err);
            })

        refresh.addEventListener('click', function () {
            window.location.reload(false);
        }, false);
    }


    const getFriendlyType = (oldType) => {
        let type ='';

        if (oldType === 'Running (points per Kilometre') {
            type = 'running'
        } else if (oldType === 'Workout (points per Kilometre') {
            type = 'workout'
        } else if (oldType === 'Walking (points per Kilometre') {
            type = 'walking'
        } else if (oldType === 'Cycling (points per Kilometre') {
            type = 'cycling'
        } else if (oldType === 'Swimming (points per Kilometre') {
            type = 'swimming'
        } else if (oldType === 'swimming') {
            type = 'swimming'
        }

        return type;
    }


    const handleActivityType = () => {
        if(
            getFriendlyType(type) === 'running' ||
            getFriendlyType(type) === 'walking' ||
            getFriendlyType(type) === 'cycling' ||
            getFriendlyType(type) === 'swimming'
        ){
            return 'Distance Traveled KM'
        }else if(getFriendlyType(type)){
            return 'Time Spent in hours'
        }
        return 'Amount'
    };

    const body = (
        <div className="wrap-addActivity">

            <div>
                <form className="login-form" id="login-form" onSubmit={handleSubmit}>
                    <h2>Add New Member</h2>
                    <AddMember/>
                    <hr/>
                    <br/>
                    <h2>Add Activity</h2>

                    <div className="form-input-material">
                        <label>Name:</label>
                        <select name="name"
                                value={username}
                                className="form-select"
                                onChange={event => setUsername(event.target.value)}
                                required>
                            <option value="disabled" disabled>Choose Member</option>

                            {usermembersname.map(usermembername => <option value={usermembername._id}
                                                                           key={usermembername._id}>{usermembername.firstName} {usermembername.surname}</option>)}
                        </select>

                    </div>
                    <hr/>
                    <div className="form-input-material">
                        <label>Activity: </label>

                        <select name="name"
                                value={type}
                                className="form-select"
                                onChange={event => setType(event.target.value)}
                                required>
                            <option value="disabled" disabled>Choose Activity</option>

                            {useractivitiestype.map(useractivitytype => <option value={useractivitytype.description}
                                                                                key={useractivitytype._id}>{ getFriendlyType(useractivitytype.description)} </option>)}
                        </select>
                    </div>

                    <div className="form-input-material">
                        <label> {handleActivityType()}:</label>

                        <input
                            type="number"
                            pattern="^\d*(\.\d{0,2})?$"
                            value={duration}
                            name="usersurname"
                            autocomplete="off"
                            onChange={event => setDuration(event.target.value)}
                            required
                        />
                    </div>

                    <hr/>

                    <Button variant="contained" type="submit" className="btn">Submit</Button>

                </form>
                <div id="new-entry"></div>
            </div>

        </div>
    );

    return (
        <>
            {body}

        </>
    )
}
export default AddActivity;