import React, {useEffect, useState} from 'react';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';

import '../leaderboard/Leaderboard.scss';
import './Leaderboards.scss';
import LeaderboardTable from '../leaderboard/leaderboardtable/LeaderboardTable';

export default function Leaderboards(props) {

    const [tableData, setTableData] = useState('');

    useEffect(() => {
        setTableData(props.data);
    }, [props])

    const updateData = (data) => {
        setTableData(data);
        return true;
    }

    return (
        <div className="section leaderboard leaderboards">
            <Container maxWidth="lg">
                <div className="leaderboard_header">
                    <h2 className="leaderboard_header_title">The Lynx Leaderboard</h2>
                    <p className="leaderboard_header_text">Each agency team is competing to earn as many points (and get
                        as much sponsorship) as possible through cycling, running, walking, swimming or working
                        out. </p>

                    <p className="leaderboard_header_text">The activities each hold different point weights that are
                        tallied up here. Make sure you tell your ambassador all the miles you’re putting in each week so
                        that your contributions can be added to our totals.</p>

                    <p className="leaderboard_header_text">The team (and individuals) with the highest point score at
                        the end of the September will be named the Gold Medallists of The Havas Lynx Games.</p>
                </div>
                <div className="leaderboard_body">
                    <div className="leaderboards_nav">
                        <h3 className="leaderboards_nav_title">View leaderboard for:</h3>
                        <Button
                            onClick={() => {
                                updateData('global')
                            }}
                            className={(tableData === 'global' ? 'MuiButtonActive' : '')}
                            size="large"
                            variant="outlined">Team</Button>
                        <Button
                            className={(tableData === 'individual' ? 'MuiButtonActive' : '')}
                            onClick={() => {
                                updateData('individual')
                            }} size="large" variant="outlined">Individual</Button>
                    </div>
                    <LeaderboardTable data={tableData}/>
                </div>
            </Container>
        </div>
    );
}
