import React from 'react';

import "./Block.scss";

const Block = ({ title, text, icon }) => (
  <>
    <div className="block">
      <div className="block_inner">
        <div className={`block_icon ${icon ? icon : ''}`}></div>
        <h3 className="block_title">{`${ title }`}</h3>
        <p className="block_text">{`${ text }`}</p>
      </div>
    </div>
  </>
)

export default Block
