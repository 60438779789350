import React, {useEffect} from "react";

import Header from '../../header/Header';
import WelcomeTeam from '../../sections/welcome/WelcomeTeam';
import Leaderboard from '../../leaderboard/Leaderboard';
import Footer from '../../footer/Footer';

export default function Team(props) {
    useEffect(() => {
        console.log(props);
    },[props])

    return (

        <>
            <div className="App">
                <Header/>
                <WelcomeTeam team={props.teamName}/>
                <Leaderboard/>
                <Footer/>
            </div>
        </>
    )
}
