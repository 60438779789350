import React, { Component } from 'react';
import Container from '@material-ui/core/Container';

import "./WhatWeAreDoing.scss";
import Block from './block/Block';

export default class WhatWeAreDoing extends Component {
  render() {

    return (
      <div className="section whatwearedoing">
        <Container className="whatwearedoing_inner" maxWidth="lg">
          <h2 className="whatwearedoingTitle">The ambition of the Havas Lynx Games</h2>
          <div className="whatwearedoingGrid">
            <Block icon="block_icon-hand" text="We want everyone to get out there and contribute whatever they can." title="Promoting Exercise" />
            <Block icon="block_icon-pound" text="We’re supporting our friends at The Christie to carry out life-saving research." title="Raising Money" />
            <Block icon="block_icon-heart" text="Through exercise and a shared goal we can reconnect with each other while we work from home." title="Reconnect" />
          </div>
        </Container>
      </div>
    )
  }
}