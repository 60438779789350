const ApiConfig = {
    url: 'https://lynx-torch-api.hlxgames.com/api',
};

const api_url = ApiConfig.url + '/';

export default api_url;



