import React, {useEffect, useState} from 'react';
import Container from '@material-ui/core/Container';
import Button from '../../button/Button';


import '../../../images/Folk-1.jpg'

import "./Welcome.scss";

export default function WelcomeTeam(props) {

  const [title, setTitle] = useState('');

  const stripName = (teamName) => {
    teamName = teamName.replace('&', '');
    teamName = teamName.replace('+', '');
    teamName = teamName.replace(' ', '');
    teamName = teamName.replace(' ', '');
    return teamName;
  }
  

  useEffect(() => {
    console.log(props);

    setTitle(props.team);

  },[props])
  
  return (
    <div className="section welcome">
      <Container className="welcome_inner" maxWidth="lg">
        <div className="welcomeFlex">
          <div className="welcomeBody">
            <h1>{title}</h1>
            <p>This is the team page for {title} who are taking part in the The Lynx Games.</p>
            <p>On this page you can follow the progress of the {title} team members as they compete against fellow teams for gold. The leaderboard on this page shows each team members progress.</p>
            <Button label="Donate" classBtn="btDonate" btnLink="https://www.justgiving.com/fundraising/hlxgames" />
          </div>
          <div className="welcomeMedia">
            <div className="welcomeMediaItem welcomeMediaItem1">
              <figure className="aspectRatio aspectRatio-1x1">
                <img
                  className="aspectRatioMedia" loading="lazy"
                  src={require(`../../../images/teams-pics/${stripName(props.team)}-1.jpg`)}
                  alt='team pic'
                />
                
              </figure>
            </div>
            <div className="welcomeMediaItem welcomeMediaItem2">
              <figure className="aspectRatio aspectRatio-1x1">
                <img 
                alt="" 
                className="aspectRatioMedia" 
                loading="lazy" 
                src={require(`../../../images/teams-pics/${stripName(props.team)}-2.jpg`)} />
              </figure>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

