import React from "react";

import Header from '../../header/Header';
import Welcome from '../../sections/welcome/Welcome';
import MoneyRaised from '../../sections/moneyraised/MoneyRaised';
import WhatWeAreDoing from '../../sections/whatwearedoing/WhatWeAreDoing';
import Leaderboards from '../../leaderboards/Leaderboards';
import Footer from '../../footer/Footer';

const Landing = () => (
  <>
    <div className="App">
      <Header />
      <Welcome />
      <MoneyRaised />
      <WhatWeAreDoing />
      <Leaderboards data="global" />
      <Footer />
    </div>
  </>
)

export default Landing;