import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import ls from "local-storage";

import "./AddMember.scss";

const api = axios.create ({
  baseURL: `https://lynx-torch-api.hlxgames.com/`,
  headers: {Authorization: `Bearer ` + ls.get('session_token')}
})



function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));



const AddMember = props => {

  const [username, setUsername] = useState('');
  const [usersurname, setUserSurname] = useState('');

  useEffect(() => {
  }, []);

  const handleSubmitMember = event => {
    event.preventDefault();

    // window.location.reload(false);
  
    api.put(`/api/member`, { firstName: username,  surname: usersurname })
      .then(res => {
        console.log(res);
        console.log(res.data);
      })
      setOpen(false);
  };

  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className="wrap-addMember">
          <form onSubmit={handleSubmitMember}>
            <h2>Add Member</h2>
          <div className="form-input-material">
            <label>First Name:<span className="field-required">*</span></label>
            <input
                type="text"
                value={username}
                name="username"
                autocomplete="off"
                onChange={event => setUsername(event.target.value)}
                required
              />
          </div>
          <hr/>
          <div className="form-input-material">
            <label>Surname:<span className="field-required">*</span> </label>
            <input
                type="text"
                value={usersurname}
                name="usersurname"
                autocomplete="off"
                onChange={event => setUserSurname(event.target.value)}
                required
              />
          </div>
          <Button variant="contained" type="submit" className="btn">Add Member</Button>
          <Button variant="contained"  onClick={handleClose}className="btn">Close</Button>
          </form>

          <p className="field-required">*required</p>
        </div>
    </div>
  );



  return (
    <>
    <div>
      <Button variant="contained" className="btn" onClick={handleOpen}>Add New Members</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
        
        
      </Modal>
    </div>
  
    </>
  )
}
export default AddMember;