import React from 'react';
import Container from '@material-ui/core/Container';
import Range from '../../range/Range';
import Button from '../../button/Button';

import "./MoneyRaised.scss";
import imageLarge from '../../../images/run-club.jpg';
import imageSmall from '../../../images/Olivia.jpg';

export default function WelcomeTeam() {
  return (
    <div className="section moneyRaised">
      <Container className="moneyRaised_inner" maxWidth="lg">
        <div className="moneyRaisedRange">
          <Range />
        </div>
        <div className="moneyRaisedFlex">
          <div className="moneyRaisedBody">
            <h2 className="fontBook moneyRaisedTitle"><span className="moneyRaisedTitleText">Going the extra mile for The Christie</span></h2>
            <p>The Havas Lynx Games is an opportunity for us to raise some much needed money in support of The Christie’s new project, COSMIC-19.</p>
            <p>COSMIC-19 is a wearable tech project designed to monitor cancer patients and reduce the contact between patients and HCPs during this time. This new tech can improve our understanding of Covid-19 and has the potential to save countless lives.</p>
            <p>Let’s go the extra mile for a charity that’s so close to so many of our hearts.</p>


            <Button label="Donate" classBtn="btDonate" btnLink="https://www.justgiving.com/fundraising/hlxgames" />
            <Button label="Discover more about COSMIC-19" btnLink="https://www.christie.nhs.uk/about-us/news-at-the-christie/latest-news-stories/manchester-researchers-use-innovative-artificial-intelligence-in-covid-19-technology-trial" />
          </div>
          <div className="moneyRaisedMedia">
            <div className="moneyRaisedMediaItem moneyRaisedMediaItem1">
              <figure className="aspectRatio aspectRatio-1x1"><img alt="" className="aspectRatioMedia" loading="lazy" src={ imageLarge } /></figure>
            </div>
            <div className="moneyRaisedMediaItem moneyRaisedMediaItem2">
              <figure className="aspectRatio aspectRatio-1x1"><img alt="" className="aspectRatioMedia" loading="lazy" src={ imageSmall } /></figure>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
