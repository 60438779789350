import React from 'react';
import Container from '@material-ui/core/Container';

import './Leaderboard.scss';
import LeaderboardTable from '../leaderboard/leaderboardtable/LeaderboardTable';

export default function Leaderboard() {
  return (
    <div className="section leaderboard">
      <Container maxWidth="lg">
        <div className="leaderboard_header">
          <h2 className="leaderboard_header_title">Leaderboard</h2>
        </div>
        <div className="leaderboard_body">
          <LeaderboardTable data="individualTeams"/>
        </div>
      </Container>
    </div>
  );
}
