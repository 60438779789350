import React from 'react';


import "./Button.scss";

const Button = ({ label, classBtn, btnLink, viewTeams }) => (
  <>
  {viewTeams ? 
  
    <button className={`btn ${classBtn ? classBtn : ''}`}>
      {`${ label }`}
    </button>
  : 
  
    <a href={`${ btnLink }`} target="_blank" rel="noopener noreferrer" className={`btn ${classBtn ? classBtn : ''}`}>
      {`${ label }`}
    </a>
  }

    
  </>

)

export default Button

