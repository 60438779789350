import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {ActivityData} from "../../global";

import "./Header.scss";
import logo from '../../images/havaslynxgames-thetorchrelay.svg';

export default function Header() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    // Temporary for Static Team Page
    // TODO: Remove when Teams are hooked up
    // TODO: Remove useHistory also
    const history = useHistory();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLinkClick = (link) => {
        history.push('/team/' + link);
    };

    const makeid = (length) => {
        let result           = '';
        let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }


    const [teams, setTeams] = useState([]);

    useEffect(() => {

        ActivityData.then((res) => {
            setTeams(res.Teams);
        });

    }, [])

    return (
        <header className="header">
            <Container maxWidth="lg">
                <div className="header_inner">
                    <div className="header_brand">
                        <Link to="/"><img src={logo} className="header_brand_logo"
                                          alt="Havas Lynx Games - The Torch Relay"/></Link>
                    </div>
                    <div className="header_nav">
                        <Button aria-controls="header-nav-menu" aria-haspopup="true" onClick={handleClick}>View
                            Teams</Button>
                        <Menu
                            id="header-nav-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleClose}>Close</MenuItem>

                            {teams.map((team) => (
                                <MenuItem key={makeid(5)} onClick={() => {
                                    handleLinkClick(team.Slug);
                                }}>{team.Name}</MenuItem>
                            ))}

                        </Menu>
                    </div>
                </div>
            </Container>
        </header>
    );
}
