import React, {useEffect, useState} from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import {ActivityData} from "../../global";

import Landing from '../pages/landing/Landing';
import Team from '../pages/team/Team';
import Admin from '../pages/admin/Admin';

export default function App() {
  const [teams, setTeams] = useState([]);
  useEffect(() => {
      ActivityData.then((res) => {
          setTeams(res.Teams);
      });
  }, [])

  return (
    <>
      <BrowserRouter>
          <Route exact path="/" component={Landing} />
          <Route exact path="/admin" component={Admin} />
          {teams.map((team) => (
            <Route
                exact
                path={`/team/${team.Slug}`}
                component={() => <Team teamName={team.Name} teamSlug={team.Slug} />}
                key={team.Name}/>
          ))}
      </BrowserRouter>
    </>
  )
  

}